import React, { Component } from 'react';
import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default class Home extends Component {

    render(){
        return (
            <div className="about-page">
                <div className="jumbotron2 text-center"></div>
                <br />
                <div className= "container text-center">
                    <h1>Episode 18 - Black and Free</h1>
                <div className="row py-4 d-flex align-items-center">
                    <div className="col-md-12 text-center"> 
                    <a href="https://open.spotify.com/episode/5njxP5xzJpp7nSgmSeIAHj?si=BxdUbj5YRNm7a72NU7RQjA&dl_branch=1" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={['fab', 'spotify']} className="mr-3" size="3x"/></a>         
                    <a href="https://podcasts.apple.com/gb/podcast/episode-18-black-and-free/id1517444320?i=1000530319679" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={['fab', 'apple']} className="mr-3" size="3x" /></a>
                    <a href="https://www.youtube.com/watch?v=yV5c2tzcxMA" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={['fab', 'youtube']} className="mr-3" size="3x" /></a>
                    </div>
                </div>
                    <p>
                    In this episode we talk about freedom of speech and how laws governing our freedom of speech affect the way we discuss topics such as homosexuality and body positivity. Later in the episode we also talk about code switching and how we think it will affect the future of black people moving forward. Would you still code switch in an interview with a black interviewer? Come hear our answer to that question and more on this episode!
                    </p>
                    <h5>
                        We would like to thank our guests Corinne, Sophie and Lucia for appearing on this episode!
                    </h5>
                    <br />
                    <iframe allow="autoplay *; encrypted-media *; fullscreen *" frameBorder={0} title="ep18" height={175} style={{width: '100%', maxWidth: '660px', overflow: 'hidden', background: 'transparent'}} sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-storage-access-by-user-activation allow-top-navigation-by-user-activation" src="https://embed.podcasts.apple.com/gb/podcast/episode-18-black-and-free/id1517444320?i=1000530319679" />
                    <br />
                    <div className="row py-4 d-flex align-items-center">
                        <div className="col-md-12">
                            <a class="btn btn-primary mr-3" href="/episodes/17" role="button">Previous Episode</a>
                            <a class="btn btn-primary" href="/episodes/19" role="button">Next Episode</a>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}