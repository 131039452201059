import React, { Component } from 'react';
import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default class Home extends Component {

    render(){
        return (
            <div className="about-page">
                <div className="jumbotron2 text-center"></div>
                <br />
                <div className= "container text-center">
                    <h1>Episode 3 - Black and Privately Educated</h1>
                <div className="row py-4 d-flex align-items-center">
                    <div className="col-md-12 text-center"> 
                    <a href="https://open.spotify.com/episode/7snpJYrQCfOPAaYHZPuP8l?si=YGaEK41qS-i-GApFn3_Szw" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={['fab', 'spotify']} className="mr-3" size="3x"/></a>         
                    <a href="https://podcasts.apple.com/gb/podcast/episode-3-black-and-privately-educated/id1517444320?i=1000484924306" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={['fab', 'apple']} className="mr-3" size="3x" /></a>
                    <a href="https://www.youtube.com/watch?v=CH4gW29FkRw" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={['fab', 'youtube']} className="mr-3" size="3x" /></a>
                    </div>
                </div>
                    <p>
                        On the first episode of Project Noir: The Interview, we look into how education differs for a black person who has had a majority their education in selective schools.
                    </p>
                    <h5>
                        We would like to thank our guest Nathan for being the focus of this episode!
                    </h5>
                    <br />
                    <iframe allow="autoplay *; encrypted-media *; fullscreen *" title="ep3" frameBorder={0} height={175} style={{width: '100%', maxWidth: '660px', overflow: 'hidden', background: 'transparent'}} sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-storage-access-by-user-activation allow-top-navigation-by-user-activation" src="https://embed.podcasts.apple.com/gb/podcast/episode-3-black-and-privately-educated/id1517444320?i=1000484924306" />
                    <br />
                    <div className="row py-4 d-flex align-items-center">
                        <div className="col-md-12">
                            <a class="btn btn-primary mr-3" href="/episodes/2" role="button">Previous Episode</a>
                            <a class="btn btn-primary" href="/episodes/4" role="button">Next Episode</a>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}