import React, { Component } from 'react';
import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default class Home extends Component {

    render(){
        return (
            <div className="about-page">
                <div className="jumbotron2 text-center"></div>
                <br />
                <div className= "container text-center">
                    <h1>Episode 10 - Black and Parenting</h1>
                <div className="row py-4 d-flex align-items-center">
                    <div className="col-md-12 text-center"> 
                    <a href="https://open.spotify.com/episode/6ZW9EFaO2xrLOMXNjywpxD?si=IlFcTpikSAG7MxiZ7k1MMg"  target="_blank" rel="noreferrer"><FontAwesomeIcon icon={['fab', 'spotify']} className="mr-3" size="3x"/></a>         
                    <a href="https://podcasts.apple.com/gb/podcast/episode-10-black-and-parenting/id1517444320?i=1000504754388"  target="_blank" rel="noreferrer"><FontAwesomeIcon icon={['fab', 'apple']} className="mr-3" size="3x" /></a>
                    <a href="https://www.youtube.com/watch?v=4YqGJkAyeYc"  target="_blank" rel="noreferrer"><FontAwesomeIcon icon={['fab', 'youtube']} className="mr-3" size="3x" /></a>
                    </div>
                </div>
                    <p>
                    In this episode, we talk to two relatively young parents who take us through their journey from conception to birth and onwards. They discuss with us the troubles they have encountered so far and how they have used family and other resources to counter those issues. Finances, moral values, culture and more are all covered in this interview.
                    </p>
                    <h5>
                        We would like to thank our guests George and Michelle for appearing on this episode!
                    </h5>
                    <br />
                    <iframe allow="autoplay *; encrypted-media *; fullscreen *" frameBorder={0} title="ep10" height={175} style={{width: '100%', maxWidth: '660px', overflow: 'hidden', background: 'transparent'}} sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-storage-access-by-user-activation allow-top-navigation-by-user-activation" src="https://embed.podcasts.apple.com/gb/podcast/episode-10-black-and-parenting/id1517444320?i=1000504754388" />
                    <br />
                    <div className="row py-4 d-flex align-items-center">
                        <div className="col-md-12">
                            <a class="btn btn-primary mr-3" href="/episodes/9" role="button">Previous Episode</a>
                            <a class="btn btn-primary" href="/episodes/11" role="button">Next Episode</a>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}