import React, { useState } from 'react';
import { db } from '../firebase';

const AffWriter = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [snapchat, setSnapchat] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    db.collection('Affirmation-Writers').add({
      name: name,
      email: email,
      snapchat: snapchat,
    })
    .then(() => {
      window.location.href = "/apply/thank-you";
    })
    .catch(() => {
      alert("If you see this message, please contact the site administator on Instagram immediately (@prince.george_).");
    }) 

    setName("");
    setEmail("");
    setSnapchat("");
  };

  return (
    <div>
      <div className="jumbotron7 text-center"></div>
      <br />
      <section className="Application text-center">
      <h3>Application for Affirmation Writer</h3>

        <div className="container" align="center">
          <p>
              We are in need of both male and female affirmation writers. If you have are a poet or just a person with a talent with words and a message, please apply below so we can reach out to you!
          </p>
          <h6>This role will be unpaid.</h6>
          <form onSubmit={handleSubmit}>
            <div className="form-group col-lg-6"> 
              <label>First Name: </label>
              <input  type="text"
                  required
                  className="form-control"
                  placeholder="First Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  />
            </div>
            <div className="form-group col-lg-6"> 
              <label>Email Address: </label>
              <input  type="text"
                  required
                  className="form-control"
                  placeholder="Email Address"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  />
            </div>
            <div className="form-group col-lg-6"> 
              <label>Snapchat: </label>
              <input  type="text"
                  required
                  className="form-control"
                  placeholder="Snapchat"
                  value={snapchat}
                  onChange={(e) => setSnapchat(e.target.value)}
                  />
            </div>
            <div className="form-group">
              <input type="submit" value="Submit" className="btn btn-primary" />
            </div>
          </form>
        </div>
        <br />
      </section>
    </div>
  )
}

export default AffWriter;