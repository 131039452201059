import React from 'react';
import "./assets/icons/";
import './App.css';
import "bootstrap/dist/css/bootstrap.min.css";

import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import NavbarPN from "./components/navbar.components";
import Home from "./components/home.components";
import Footer from "./components/footer.components";
import About from "./components/about.components";
import Contact from "./components/contact.component";
import Ep1 from "./components/episode1.component";
import Ep2 from "./components/episode2.component";
import Ep3 from "./components/episode3.component";
import Ep4 from "./components/episode4.component";
import Ep5 from "./components/episode5.component";
import Ep6 from "./components/episode6.component";
import Ep7 from "./components/episode7.component";
import Ep8 from "./components/episode8.component";
import Ep9 from "./components/episode9.component";
import Ep10 from "./components/episode10.component";
import Ep11 from "./components/episode11.component";
import Ep12 from "./components/episode12.component";
import Ep13 from "./components/episode13.components";
import Ep14 from "./components/episode14.component";
import Ep15 from "./components/episode15.component";
import Ep16 from "./components/episode16.component";
import Ep17 from "./components/episode17.component";
import Ep18 from "./components/episode18.component";
import Ep19 from "./components/episode19.component";
import Ep20 from "./components/episode20.component";
import Episodes from './components/episodes.component';
import Apply from "./components/apply.component";
import MAff from "./components/male-affirmations.component";
import FAff from "./components/female-affirmations.component";
import ComeOnEp from "./components/come-on-ep.component";
import MBC from "./components/male-bc.components";
import BHBC from "./components/bh-book-club.component";
import FemHost from "./components/female-host.components";
import Researcher from "./components/researcher.component";
import AffWriter from "./components/affirmation-writer.component";
import Thanks from "./components/thanks.component";
import Four from "./components/404.component";

function App() {
  return (
    <Router>
        <NavbarPN />
        <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/episodes" exact component={Episodes} />
        <Route path="/about-us" component={About} />
        <Route path="/apply" exact component={Apply} />
        <Route path="/contact-us" component={Contact} />
        <Route path="/episodes/1" component={Ep1} />
        <Route path="/episodes/2" component={Ep2} />
        <Route path="/episodes/3" component={Ep3} />
        <Route path="/episodes/4" component={Ep4} />
        <Route path="/episodes/5" component={Ep5} />
        <Route path="/episodes/6" component={Ep6} />
        <Route path="/episodes/7" component={Ep7} />
        <Route path="/episodes/8" component={Ep8} />
        <Route path="/episodes/9" component={Ep9} />
        <Route path="/episodes/10" component={Ep10} />
        <Route path="/episodes/11" component={Ep11} />
        <Route path="/episodes/12" component={Ep12} />
        <Route path="/episodes/13" component={Ep13} />
        <Route path="/episodes/14" component={Ep14} />
        <Route path="/episodes/15" component={Ep15} />
        <Route path="/episodes/16" component={Ep16} />
        <Route path="/episodes/17" component={Ep17} />
        <Route path="/episodes/18" component={Ep18} />
        <Route path="/episodes/19" component={Ep19} />
        <Route path="/episodes/20" component={Ep20} />
        <Route path="/apply/affirmations-for-men" component={MAff} />
        <Route path="/apply/come-on-episode" component={ComeOnEp} />
        <Route path="/apply/what-is-a-man" component={MBC} />
        <Route path="/apply/black-history-book-club" component={BHBC} />
        <Route path="/apply/affirmations-for-women" component={FAff} />
        <Route path="/apply/female-podcast-host" component={FemHost} />
        <Route path="/apply/social-media-coordinator" component={Researcher} />
        <Route path="/apply/affirmations-writer" component={AffWriter} />
        <Route path="/apply/thank-you" component={Thanks} />
        <Route path="*" component={Four} />
        </Switch>
        <Footer />
    </Router>
  );
}

export default App;
