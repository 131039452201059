import React, { useState } from 'react';
import { db } from '../firebase';

const FAff = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    db.collection('FemAff-Applicants').add({
      name: name,
      email: email,
    })
    .then(() => {
      window.location.href = "/apply/thank-you";
    })
    .catch(() => {
      alert("If you see this message, please contact the site administator on Instagram immediately (@prince.george_).");
    }) 

    setName("");
    setEmail("");
  };

  return (
    <div>
      <div className="jumbotron7 text-center"></div>
      <section className="Application text-center">
      <br />
      <h3>Application for Female Affirmations</h3>

        <div className="container" align="center">
          <p>
            Written by women, for women. These affirmations aim to speak encouragement into your life and remind you of your value. These positive affirmations are here to let you know that you are loved and valued dearly. Relationships, Mental Health and Confidence and more will be covered by these messages which will be sent out at least every other week.
          </p>
          <form onSubmit={handleSubmit}>
            <div className="form-group col-lg-6"> 
              <label>First Name: </label>
              <input  type="text"
                  required
                  className="form-control"
                  placeholder="First Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  />
            </div>
            <div className="form-group col-lg-6"> 
              <label>Email Address: </label>
              <input  type="text"
                  required
                  className="form-control"
                  placeholder="Email Address"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  />
            </div>
            <div className="form-group">
              <input type="submit" value="Submit" className="btn btn-primary" />
            </div>
          </form>
        </div>
        <br />
      </section>
    </div>
  )
}

export default FAff;