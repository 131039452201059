import React, { Component } from 'react';
import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default class Home extends Component {

    render(){
        return (
            <div className="about-page">
                <div className="jumbotron2 text-center"></div>
                <br />
                <div className= "container text-center">
                    <h1>Episode 11 - Black and Rebuilding</h1>
                <div className="row py-4 d-flex align-items-center">
                    <div className="col-md-12 text-center"> 
                    <a href="https://open.spotify.com/episode/4NvbfMcB4rU75YuiECfsHP?si=GmbxNTSmThSMdzdi5B7Naw" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={['fab', 'spotify']} className="mr-3" size="3x"/></a>         
                    <a href="https://podcasts.apple.com/gb/podcast/episode-11-black-and-rebuilding/id1517444320?i=1000505424167" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={['fab', 'apple']} className="mr-3" size="3x" /></a>
                    <a href="https://www.youtube.com/watch?v=UA94AoaK528" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={['fab', 'youtube']} className="mr-3" size="3x" /></a>
                    </div>
                </div>
                    <p>
                    After slavery, the black people in America slowly but surely began to make a living for themselves within White America. Not too long after, you could see self-sufficient communities of black people residing and thriving. In this episode, we cover what happened to some of these communities and identify some of the reasons why we don't see many of these purely black owned spaces in existence today.
                    </p>
                    <br />
                    <iframe allow="autoplay *; encrypted-media *; fullscreen *" frameBorder={0} height={175} title="ep11" style={{width: '100%', maxWidth: '660px', overflow: 'hidden', background: 'transparent'}} sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-storage-access-by-user-activation allow-top-navigation-by-user-activation" src="https://embed.podcasts.apple.com/gb/podcast/episode-11-black-and-rebuilding/id1517444320?i=1000505424167" />
                    <br />
                    <div className="row py-4 d-flex align-items-center">
                        <div className="col-md-12">
                            <a class="btn btn-primary mr-3" href="/episodes/10" role="button">Previous Episode</a>
                            <a class="btn btn-primary" href="/episodes/12" role="button">Next Episode</a>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}