import React, { Component } from 'react';
import "bootstrap/dist/css/bootstrap.min.css";

export default class NavbarPN extends Component {

    render(){
        return (
             <nav class="navbar navbar-expand-lg navbar-dark main-navigation fixed-top" styles={"backgroundColor: #262626"}>
                 <div class="container">
                     <a class="navbar-brand" href="/">Project Noir</a>
                     <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                     <span class="navbar-toggler-icon"></span>
                     </button>
                     <div class="collapse navbar-collapse" id="navbarSupportedContent">
                         <ul class="navbar-nav ml-auto">
                             <li class="nav-item">
                                 <a class="nav-link active" href="/">Home</a>
                             </li>
                             <li class="nav-item">
                                 <a class="nav-link active" href="/about-us">About Us</a>
                             </li>
                             <li class="nav-item">
                                 <a class="nav-link active" href="/episodes">Episodes</a>
                             </li>
                             <li class="nav-item">
                                 <a class="nav-link active" href="/apply">Apply</a>
                             </li>
                             <li class="nav-item">
                                 <a class="nav-link active" href="/contact-us">Contact Us</a>
                             </li>
                         </ul>
                     </div>
                 </div>
             </nav>
        )
    }
}