import React, { Component } from 'react';
import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default class Home extends Component {

    render(){
        return (
            <div className="about-page">
                <div className="jumbotron2 text-center"></div>
                <br />
                <div className= "container text-center">
                    <h1>Episode 6 - Black and Colourist</h1>
                <div className="row py-4 d-flex align-items-center">
                    <div className="col-md-12 text-center"> 
                    <a href="https://open.spotify.com/episode/0luy5Uh84jh34qsfnbvmiO?si=SiGh5GEdTjqOJcltC4ovGA" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={['fab', 'spotify']} className="mr-3" size="3x"/></a>         
                    <a href="https://podcasts.apple.com/gb/podcast/episode-6-black-and-colourist/id1517444320?i=1000490384100" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={['fab', 'apple']} className="mr-3" size="3x" /></a>
                    <a href="https://www.youtube.com/watch?v=Oie0p8xIeo0" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={['fab', 'youtube']} className="mr-3" size="3x" /></a>
                    </div>
                </div>
                    <p>
                    This episode we dig deep into the history and timeline of Colourism and how it effects our society today. We look into how Colourism controls our attraction to each other as well as how it affects our expectations of each other’s character.
                    </p>
                    <h5>
                        We would like to thank our guest Kierra for appearing on this episode!
                    </h5>
                    <br />
                    <iframe allow="autoplay *; encrypted-media *; fullscreen *" frameBorder={0} title="ep6" height={175} style={{width: '100%', maxWidth: '660px', overflow: 'hidden', background: 'transparent'}} sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-storage-access-by-user-activation allow-top-navigation-by-user-activation" src="https://embed.podcasts.apple.com/gb/podcast/episode-6-black-and-colourist/id1517444320?i=1000490384100" />
                    <br />
                    <div className="row py-4 d-flex align-items-center">
                        <div className="col-md-12">
                            <a class="btn btn-primary mr-3" href="/episodes/5" role="button">Previous Episode</a>
                            <a class="btn btn-primary" href="/episodes/7" role="button">Next Episode</a>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}