import React, { Component } from 'react';
import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default class Home extends Component {

    render(){
        return (
            <div className="about-page">
                <div className="jumbotron2 text-center"></div>
                <br />
                <div className= "container text-center">
                    <h1>Episode 16 - Black and Insecure</h1>
                <div className="row py-4 d-flex align-items-center">
                    <div className="col-md-12 text-center"> 
                    <a href="https://open.spotify.com/episode/6gDLCnOnngzy5nsMsPBJ12?si=i0CmvjDeQIOKMQLVqRLI3w" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={['fab', 'spotify']} className="mr-3" size="3x"/></a>         
                    <a href="https://podcasts.apple.com/gb/podcast/episode-16-black-and-insecure/id1517444320?i=1000518795094" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={['fab', 'apple']} className="mr-3" size="3x" /></a>
                    <a href="https://www.youtube.com/watch?v=2KLmpwVRq6k" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={['fab', 'youtube']} className="mr-3" size="3x" /></a>
                    </div>
                </div>
                    <p>
                    This episode, the first of our new 'Girls Panel' series, aims to tackle the topic of insecurities, anxiety and everything that plagues the female mind. The Project Noir hosts have taken a step back and allowed different voices, free of male, masculine bias, to control the conversation and inform us all on the influences and effects of these mental issues on the female mind. Today's girls share their own stories and experiences as well as discussing the sources of their insecurities as they advise each other and listeners on how to begin to overcome your insecurities.
                    </p>
                    <h5>
                        We would like to thank our guests Kierra, Kim, Sophie, Lucia, Corinne, Whitney and Jevean for appearing on this episode!
                    </h5>
                    <br />
                    <iframe allow="autoplay *; encrypted-media *; fullscreen *" frameBorder={0} title="Ep16" height={175} style={{width: '100%', maxWidth: '660px', overflow: 'hidden', background: 'transparent'}} sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-storage-access-by-user-activation allow-top-navigation-by-user-activation" src="https://embed.podcasts.apple.com/gb/podcast/episode-16-black-and-insecure/id1517444320?i=1000518795094" />
                    <br />
                    <div className="row py-4 d-flex align-items-center">
                        <div className="col-md-12">
                            <a class="btn btn-primary mr-3" href="/episodes/15" role="button">Previous Episode</a>
                            <a class="btn btn-primary" href="/episodes/17" role="button">Next Episode</a>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}