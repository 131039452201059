import React, { Component } from 'react';
import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default class Home extends Component {

    render(){
        return (
            <div className="about-page">
                <div className="jumbotron2 text-center"></div>
                <br />
                <div className= "container text-center">
                    <h1>Episode 17 - Black and Dating</h1>
                <div className="row py-4 d-flex align-items-center">
                    <div className="col-md-12 text-center"> 
                    <a href="https://open.spotify.com/episode/5njxP5xzJpp7nSgmSeIAHj?si=gFdkN3J4SPGEQ7zowFA8QQ&dl_branch=1" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={['fab', 'spotify']} className="mr-3" size="3x"/></a>         
                    <a href="https://podcasts.apple.com/gb/podcast/episode-16-black-and-insecure/id1517444320?i=1000518795094" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={['fab', 'apple']} className="mr-3" size="3x" /></a>
                    <a href="https://www.youtube.com/watch?v=2KLmpwVRq6k" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={['fab', 'youtube']} className="mr-3" size="3x" /></a>
                    </div>
                </div>
                    <p>
                    This episode is a laid back one in which the cast, and guests, discuss their expectations from a potential/current partner, share past experiences and take part in 'Accountability Hour' where they analyse their own downfalls when it comes to relationships.
                    </p>
                    <h5>
                        We would like to thank our guests Tiffany, Whitney and Kierra for appearing on this episode!
                    </h5>
                    <br />
                    <div className="yt-embed mx-auto">
                    <iframe className="yt-video" src="https://www.youtube.com/embed/hKSz29-Q0-U" title="YouTube video player" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
                    </div>
                    <br />
                    <div className="row py-4 d-flex align-items-center">
                        <div className="col-md-12">
                            <a class="btn btn-primary mr-3" href="/episodes/16" role="button">Previous Episode</a>
                            <a class="btn btn-primary" href="/episodes/18" role="button">Next Episode</a>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}