import React, { Component } from 'react';
import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default class Episode extends Component {

    render(){
        return (
            <div className="about-page">
                <div className="jumbotron2 text-center"></div>
                <br />
                <div className= "container text-center">
                    <h1>Episode 1 - Black and Protesting</h1>
                <div className="row py-4 d-flex align-items-center">
                    <div className="col-md-12 text-center"> 
                    <a href="https://open.spotify.com/episode/7rJWmRDMBoAeIHc0sjkphA?si=LahnwTPTQ4WJM-gTTxehFA" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={['fab', 'spotify']} className="mr-3" size="3x"/></a>         
                    <a href="https://podcasts.apple.com/gb/podcast/episode-1-black-and-protesting/id1517444320?i=1000477193801" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={['fab', 'apple']} className="mr-3" size="3x" /></a>
                    <a href="https://www.youtube.com/watch?v=0wexvAsxIzE" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={['fab', 'youtube']} className="mr-3" size="3x" /></a>
                    </div>
                </div>
                    <p>
                    Episode one covers the recent killings of George Floyd and Ahmaud Arbery as well as the backlash that came from it. Join us as we discuss how our community has reacted to these events, and listen in as we discuss the next steps we should be taking as a community.
                    </p>
                    <br />
                    <iframe allow="autoplay *; encrypted-media *; fullscreen *" title ="ep1" frameBorder={0} height={175} style={{width: '100%', maxWidth: '660px', overflow: 'hidden', background: 'transparent'}} sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-storage-access-by-user-activation allow-top-navigation-by-user-activation" src="https://embed.podcasts.apple.com/gb/podcast/episode-1-black-and-protesting/id1517444320?i=1000477193801" />
                    <br />
                    <div className="row py-4 d-flex align-items-center">
                        <div className="col-md-12">
                            <a class="btn btn-primary" href="/episodes/2" role="button">Next Episode</a>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}