import React, { Component } from 'react';
import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default class Home extends Component {

    render(){
        return (
            <div className="about-page">
                <div className="jumbotron2 text-center"></div>
                <br />
                <div className= "container text-center">
                    <h1>Episode 4 - Black and Interracial Dating</h1>
                <div className="row py-4 d-flex align-items-center">
                    <div className="col-md-12 text-center"> 
                    <a href="https://open.spotify.com/episode/47ynUWfv8qD7sNlV8eT6ws?si=rv8Pyda6RrSVr6yMkhJW2Q" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={['fab', 'spotify']} className="mr-3" size="3x"/></a>         
                    <a href="https://podcasts.apple.com/gb/podcast/episode-3-black-and-privately-educated/id1517444320?i=1000484924306" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={['fab', 'apple']} className="mr-3" size="3x" /></a>
                    <a href="https://www.youtube.com/watch?v=sAcYC7hY7Ls" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={['fab', 'youtube']} className="mr-3" size="3x" /></a>
                    </div>
                </div>
                    <p>
                    This episode covers the narratives which cover interrracial dating and discusses whether one can be pro black while choosing to be partnered with someone of another race. This epsiode also defines and discusses self hate and how it can influence the way that one treats their partner.
                    </p>
                    <br />
                    <iframe allow="autoplay *; encrypted-media *; fullscreen *" frameBorder={0} title="ep4" height={175} style={{width: '100%', maxWidth: '660px', overflow: 'hidden', background: 'transparent'}} sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-storage-access-by-user-activation allow-top-navigation-by-user-activation" src="https://embed.podcasts.apple.com/gb/podcast/episode-4-black-and-interracial-dating/id1517444320?i=1000486974477" />
                    <br />
                    <div className="row py-4 d-flex align-items-center">
                        <div className="col-md-12">
                            <a class="btn btn-primary mr-3" href="/episodes/3" role="button">Previous Episode</a>
                            <a class="btn btn-primary" href="/episodes/5" role="button">Next Episode</a>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}