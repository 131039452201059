import React, { Component } from 'react';
import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default class Home extends Component {

    render(){
        return (
            <div className="about-page">
                <div className="jumbotron2 text-center"></div>
                <br />
                <div className= "container text-center">
                    <h1>Episode 14 - Black and Stereotyped (Part 1)</h1>
                <div className="row py-4 d-flex align-items-center">
                    <div className="col-md-12 text-center"> 
                    <a href="https://open.spotify.com/episode/2ObIMSj25lr8WbE1z1UBFE?si=4FKCp6l4TCmKmdDuGpqpNA" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={['fab', 'spotify']} className="mr-3" size="3x"/></a>         
                    <a href="https://podcasts.apple.com/gb/podcast/episode-14-black-and-stereotyped-part-1/id1517444320?i=1000512955960" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={['fab', 'apple']} className="mr-3" size="3x" /></a>
                    <a href="https://www.youtube.com/watch?v=0gFJQNXhjJ4" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={['fab', 'youtube']} className="mr-3" size="3x" /></a>
                    </div>
                </div>
                    <p>
                    This is the first part of our episode discussing stereotypes and how they can affect the way that you are treated by others. Microaggressions are so commonplace in our society, and in this episode we cover how microaggressions are committed by people based on the stereotypes they had heard about a particular group of people. The way movements, such as the Black Lives Matter protests, are handled can change based on the stereotypes of the people who are attending. We discuss our stories of getting stereotyped, not only at school and work, but also while apart of these movements.
                    </p>
                    <h5>
                        We would like to thank our guest Darren for appearing on this episode!
                    </h5>
                    <br />
                    <iframe allow="autoplay *; encrypted-media *; fullscreen *" frameBorder={0} height={175} title="Ep14" style={{width: '100%', maxWidth: '660px', overflow: 'hidden', background: 'transparent'}} sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-storage-access-by-user-activation allow-top-navigation-by-user-activation" src="https://embed.podcasts.apple.com/gb/podcast/episode-14-black-and-stereotyped-part-1/id1517444320?i=1000512955960" />
                    <br />
                    <div className="row py-4 d-flex align-items-center">
                        <div className="col-md-12">
                            <a class="btn btn-primary mr-3" href="/episodes/13" role="button">Previous Episode</a>
                            <a class="btn btn-primary" href="/episodes/15" role="button">Next Episode</a>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}