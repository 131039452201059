import React, { useState } from 'react';
import { db } from '../firebase';

const ComeOnEp = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [snapchat, setSnapchat] = useState("");
  const [luton, setLuton] = useState("");
  const [ideas, setIdeas] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    db.collection('Episode-Applicants').add({
      name: name,
      email: email,
      snapchat: snapchat,
      luton: luton,
      ideas: ideas,
    })
    .then(() => {
      window.location.href = "/apply/thank-you";
    })
    .catch(() => {
      alert("If you see this message, please contact the site administator on Instagram immediately (@prince.george_).");
    }) 

    setName("");
    setEmail("");
    setSnapchat("");
    setLuton("");
    setIdeas("");
  };

  return (
    <div>
      <div className="jumbotron text-center"></div>
      <section className="Application text-center">
      <h3>Application to Come on an Episode</h3>
      
        <div className="container" align="center">
        <p>This application form is for anyone who is looking to join us on an episode.</p>
        <p>Below you will be asked if you would want to record in person or remotely. Please also indicate if you have no preference and are available for both options.</p>
          <form onSubmit={handleSubmit}>
            <div className="form-group col-lg-6"> 
              <label>First Name: </label>
              <input  type="text"
                  required
                  className="form-control"
                  placeholder="First Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  />
            </div>
            <div className="form-group col-lg-6"> 
              <label>Email Address: </label>
              <input  type="text"
                  required
                  className="form-control"
                  placeholder="Email Address"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  />
            </div>
            <div className="form-group col-lg-6"> 
              <label>Snapchat: </label>
              <input  type="text"
                  required
                  className="form-control"
                  placeholder="Snapchat"
                  value={snapchat}
                  onChange={(e) => setSnapchat(e.target.value)}
                  />
            </div>
            <div className="form-group col-lg-6"> 
              <label>Would you like to record in person (Leagrave, Luton) or remotely? </label>
              <input  type="text"
                  required
                  className="form-control"
                  value={luton}
                  onChange={(e) => setLuton(e.target.value)}
                  />
            </div>
            <div className="form-group col-lg-6"> 
              <label>Are there specific topics you'd like to discuss?</label>
              <textarea  type="text"
                  required
                  className="form-control"
                  placeholder="Please enter N/A if you have no ideas"
                  rows="3"
                  value={ideas}
                  onChange={(e) => setIdeas(e.target.value)}
                  />
            </div>
            <div className="form-group">
              <input type="submit" value="Submit" className="btn btn-primary" />
            </div>
          </form>
        </div>
        <br />
      </section>
    </div>
  )
}

export default ComeOnEp;