import React, { Component } from 'react';
import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default class Home extends Component {

    render(){
        return (
            <div className="about-page">
                <div className="jumbotron2 text-center"></div>
                <br />
                <div className= "container text-center">
                    <h1>Episode 19 - Black and Discipined</h1>
                <div className="row py-4 d-flex align-items-center">
                    <div className="col-md-12 text-center"> 
                    <a href="https://open.spotify.com/episode/3fTOg2K0W8P6eAHWfHLjEY?si=b3c9670885b14d36" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={['fab', 'spotify']} className="mr-3" size="3x"/></a>         
                    <a href="https://podcasts.apple.com/gb/podcast/episode-19-black-and-discplined/id1517444320?i=1000537233680" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={['fab', 'apple']} className="mr-3" size="3x" /></a>
                    <a href="https://www.youtube.com/watch?v=UQfIhpF_DdQ" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={['fab', 'youtube']} className="mr-3" size="3x" /></a>
                    </div>
                </div>
                    <p>
                    In this episode, we take a trip back to secondary school and discuss the attitudes our schools took towards discipline throughout our time there. We analyse the teacher-student dynamic and determine both how we think it should be changed, and how we think it has affected the way our life has shaped out up to this point.
                    </p>
                    <h5>
                        We would like to thank our guests Samuel and Morgan for appearing on this episode!
                    </h5>
                    <br />
                    <iframe allow="autoplay *; encrypted-media *; fullscreen *" frameBorder={0} height={175} title={"19"} style={{width: '100%', maxWidth: '660px', overflow: 'hidden', background: 'transparent'}} sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-storage-access-by-user-activation allow-top-navigation-by-user-activation" src="https://embed.podcasts.apple.com/gb/podcast/episode-19-black-and-discplined/id1517444320?i=1000537233680" />
                    <br />
                    <div className="row py-4 d-flex align-items-center">
                    <div className="col-md-12">
                            <a class="btn btn-primary mr-3" href="/episodes/18" role="button">Previous Episode</a>
                            <a class="btn btn-primary" href="/episodes/20" role="button">Next Episode</a>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}