import React, { Component } from 'react';
import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default class Episode extends Component {

    render(){
        return (
            <div className="about-page">
                <div className="jumbotron2 text-center"></div>
                <br />
                <div className= "container text-center">
                    <h1>Episode 2 - Black and Oppressed</h1>
                <div className="row py-4 d-flex align-items-center">
                    <div className="col-md-12 text-center"> 
                    <a href="https://open.spotify.com/episode/1DRpVCMqzE2vw2saZxBrBN?si=-eTwHsK5SCW34cCv5T1R1Q" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={['fab', 'spotify']} className="mr-3" size="3x"/></a>         
                    <a href="https://podcasts.apple.com/gb/podcast/episode-2-black-and-oppressed/id1517444320?i=1000477789809" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={['fab', 'apple']} className="mr-3" size="3x" /></a>
                    <a href="https://www.youtube.com/watch?v=tDVdfEOEo6g" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={['fab', 'youtube']} className="mr-3" size="3x" /></a>
                    </div>
                </div>
                    <p>
                    In this episode, we take a deeper look into how living under the American system has put African Americans in the position they are today. We teach the history of how the law, politics and the media played a role in swaying public opinion on black people in the United States.
                    </p>
                    <br />
                    <iframe allow="autoplay *; encrypted-media *; fullscreen *" title="ep2" frameBorder={0} height={175} style={{width: '100%', maxWidth: '660px', overflow: 'hidden', background: 'transparent'}} sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-storage-access-by-user-activation allow-top-navigation-by-user-activation" src="https://embed.podcasts.apple.com/gb/podcast/episode-2-black-and-oppressed/id1517444320?i=1000477789809" />
                    <br />
                    <div className="row py-4 d-flex align-items-center">
                        <div className="col-md-12">
                            <a class="btn btn-primary mr-3" href="/episodes/1" role="button">Previous Episode</a>
                            <a class="btn btn-primary" href="/episodes/3" role="button">Next Episode</a>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}