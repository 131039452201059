import React, { Component } from 'react';
import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default class Home extends Component {

    render(){
        return (
            <div className="about-page">
                <div className="jumbotron2 text-center"></div>
                <br />
                <div className= "container text-center">
                    <h1>Episode 20 - Black and Dating (Part 2)</h1>
                <div className="row py-4 d-flex align-items-center">
                    <div className="col-md-12 text-center"> 
                    <a href="https://open.spotify.com/episode/5YhWV0BYIfSS8lA55ZpzUj?si=97d6a6f1af364f8f" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={['fab', 'spotify']} className="mr-3" size="3x"/></a>         
                    <a href="https://podcasts.apple.com/gb/podcast/episode-20-black-and-dating-part-2/id1517444320?i=1000551674547" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={['fab', 'apple']} className="mr-3" size="3x" /></a>
                    <a href="https://www.youtube.com/watch?v=LVi90jPCxRY" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={['fab', 'youtube']} className="mr-3" size="3x" /></a>
                    </div>
                </div>
                    <p>
                    In this episode, the cast are again joined by Whitney and Kierra, but this time are also joined Jubril as they discuss modern dating. The conversation revolves around ones expectations in a partner, as well as their turn offs as far as behaviour and even future aspirations.
                    </p>
                    <h5>
                        We would like to thank our guests Whitney, Kierra and Jubril for appearing on this episode!
                    </h5>
                    <br />
                    <iframe allow="autoplay *; encrypted-media *; fullscreen *" frameBorder={0} height={175} title={"20"} style={{width: '100%', maxWidth: '660px', overflow: 'hidden', background: 'transparent'}} sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-storage-access-by-user-activation allow-top-navigation-by-user-activation" src="https://embed.podcasts.apple.com/gb/podcast/episode-20-black-and-dating-part-2/id1517444320?i=1000551674547" />
                    <br />
                    <div className="row py-4 d-flex align-items-center">
                        <div className="col-md-12">
                            <a class="btn btn-primary" href="/episodes/19" role="button">Previous Episode</a>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}