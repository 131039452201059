import React, { Component } from 'react';
import "bootstrap/dist/css/bootstrap.min.css";

export default class Four extends Component {

    render(){
        return (
            <div className="404-page">
                <div className="jumbotron12 text-center"></div>
                <br />
                <div className= "container text-center">
                    <h1>Well... This is awkward...</h1>
                    <p>
                    Long story short, you've gotten lost. Either this is the result of you snooping around or the site having a broken link somewhere.
                    </p>
                    <p>
                        If it is the latter, please contact the site administrator on Instagram at @prince.george_ to let him know what has happened and how it happened. Thank you.
                    </p>
                    <h5>Please use the navigation links to get back to your desired part of the site.</h5>
                    <br />
                </div>
            </div>
        )
    }
}