import React, { useState } from 'react';
import { db } from '../firebase';

const MBC = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [snapchat, setSnapchat] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    db.collection('Male-Book-Applicants').add({
      name: name,
      email: email,
      snapchat: snapchat,
    })
    .then(() => {
      window.location.href = "/apply/thank-you";
    })
    .catch(() => {
      alert("If you see this message, please contact the site administator on Instagram immediately (@prince.george_).");
    }) 

    setName("");
    setEmail("");
    setSnapchat("");
  };

  return (
    <div>
      <div className="jumbotron10 text-center"></div>
      <br />
      <section className="Application text-center">
      <h3>Application for the "What is a Man?" Book Club</h3>

        <div className="container" align="center">
          <p>The definition of a man is more blurred now than ever before. It is thus crucial for men to congregate to read and discuss different aspects in the life of a man such as mental health, sexuality and our role in a relationship. With weekly meetings, and in a male-only space, we will define what being a man is and hold ourselves accountable in times of falling short of that vision in a judgement-free zone.</p>
          <p>Don't be afraid of the phrase book club. Even if you're not an avid reader, we encourage you to apply and try one of our sessions out.</p>
          <form onSubmit={handleSubmit}>
            <div className="form-group col-lg-6"> 
              <label>First Name: </label>
              <input  type="text"
                  required
                  className="form-control"
                  placeholder="First Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  />
            </div>
            <div className="form-group col-lg-6"> 
              <label>Email Address: </label>
              <input  type="text"
                  required
                  className="form-control"
                  placeholder="Email Address"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  />
            </div>
            <div className="form-group col-lg-6"> 
              <label>Snapchat: </label>
              <input  type="text"
                  required
                  className="form-control"
                  placeholder="Snapchat"
                  value={snapchat}
                  onChange={(e) => setSnapchat(e.target.value)}
                  />
            </div>
            <div className="form-group">
              <input type="submit" value="Submit" className="btn btn-primary" />
            </div>
          </form>
        </div>
        <br />
      </section>
    </div>
  )
}

export default MBC;