import React, { Component } from 'react';
import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default class Home extends Component {

    render(){
        return (
            <div className="about-page">
                <div className="jumbotron2 text-center"></div>
                <br />
                <div className= "container text-center">
                    <h1>Episode 13 - Black and Being a Man</h1>
                <div className="row py-4 d-flex align-items-center">
                    <div className="col-md-12 text-center"> 
                    <a href="https://open.spotify.com/episode/3mFtB2SdfEGVeGvRMAAhRh?si=HYQS0lB7Qq-rrWoO7CIM6w" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={['fab', 'spotify']} className="mr-3" size="3x"/></a>         
                    <a href="https://podcasts.apple.com/gb/podcast/episode-13-black-and-being-a-man/id1517444320?i=1000509752992" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={['fab', 'apple']} className="mr-3" size="3x" /></a>
                    <a href="https://youtu.be/uLUrDcSZic8" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={['fab', 'youtube']} className="mr-3" size="3x" /></a>
                    </div>
                </div>
                    <p>
                    In this episode, we bring on a couple of the mandem to discuss what being a man really means. We take our time to discuss navigating aspects of male life such as mental health, relationships and parenthood. Near the end, we put ourselves in the shoes of our unborn sons and discuss the world they will be living in as well as how different fathering styles will shape them in different ways. The view of manhood in the UK and the US are also compared.
                    </p>
                    <h5>
                        We would like to thank our guests Demilade and Louis for appearing on this episode!
                    </h5>
                    <br />
                    <iframe allow="autoplay *; encrypted-media *; fullscreen *" frameBorder={0} title="ep13" height={175} style={{width: '100%', maxWidth: '660px', overflow: 'hidden', background: 'transparent'}} sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-storage-access-by-user-activation allow-top-navigation-by-user-activation" src="https://embed.podcasts.apple.com/gb/podcast/episode-13-black-and-being-a-man/id1517444320?i=1000509752992" />
                    <br />
                    <div className="row py-4 d-flex align-items-center">
                        <div className="col-md-12">
                            <a class="btn btn-primary mr-3" href="/episodes/12" role="button">Previous Episode</a>
                            <a class="btn btn-primary" href="/episodes/14" role="button">Next Episode</a>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}