import React, { useState } from 'react';
import { db } from '../firebase';

const BHBC = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [snapchat, setSnapchat] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    db.collection('BH-Book-Applicants').add({
      name: name,
      email: email,
      snapchat: snapchat,
    })
    .then(() => {
      window.location.href = "/apply/thank-you";
    })
    .catch(() => {
      alert("If you see this message, please contact the site administator on Instagram immediately (@prince.george_).");
    }) 


    setName("");
    setEmail("");
    setSnapchat("");
  };

  return (
    <div>
      <div className="jumbotron10 text-center"></div>
      <br />
      <section className="Application text-center">
      <h3>Application for the Black History Book Club</h3>

        <div className="container" align="center">
          <p>Black history in schools is not what it ideally would be. In the very first episode of Project Noir, Prince discussed the neccessity of doing your own research and now Project Noir is dedicated to helping you do that.</p>
          <p>This application form is to register your interest. More information will be emailed to you when it becomes available.</p>
          <form onSubmit={handleSubmit}>
            <div className="form-group col-lg-6"> 
              <label>First Name: </label>
              <input  type="text"
                  required
                  className="form-control"
                  placeholder="First Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  />
            </div>
            <div className="form-group col-lg-6"> 
              <label>Email Address: </label>
              <input  type="text"
                  required
                  className="form-control"
                  placeholder="Email Address"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  />
            </div>
            <div className="form-group col-lg-6"> 
              <label>Snapchat: </label>
              <input  type="text"
                  required
                  className="form-control"
                  placeholder="Snapchat"
                  value={snapchat}
                  onChange={(e) => setSnapchat(e.target.value)}
                  />
            </div>
            <div className="form-group">
              <input type="submit" value="Submit" className="btn btn-primary" />
            </div>
          </form>
        </div>
        <br />
      </section>
    </div>
  )
}

export default BHBC;