import React, { Component } from 'react';
import "bootstrap/dist/css/bootstrap.min.css";
import { Carousel } from 'react-bootstrap';

import image1 from "./../assets/images/Hapt.jpg";
import image2 from "./../assets/images/X2.jpg";
import image3 from "./../assets/images/King.jpg";

export default class Home extends Component {

    render(){
        return (
            <Carousel>
                <Carousel.Item>
                    <img
                    className="d-block w-100"
                    src={image1}
                    alt="First slide"
                    />
                    <Carousel.Caption>
                    <h4>Who are we?</h4>
                    <p class="lead">
                        <a class="btn btn-dark btn-sm" href="/about-us" role="button">Click here to find out</a>
                    </p>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <img
                    className="d-block w-100"
                    src={image2}
                    alt="Second slide"
                    />

                    <Carousel.Caption>
                    <h4>Episode List</h4>
                    <p class="lead">
                        <a class="btn btn-dark btn-sm" href="/episodes" role="button">Click here to listen</a>
                    </p>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <img
                    className="d-block w-100"
                    src={image3}
                    alt="Third slide"
                    />

                    <Carousel.Caption>
                    <h4>Come on an Episode</h4>
                    <p class="lead">
                        <a class="btn btn-dark btn-sm" href="/apply" role="button">Click here to Apply</a>
                    </p>
                    </Carousel.Caption>
                </Carousel.Item>
            </Carousel>
        )
    }
}