import React, { useState } from 'react';
import { db } from '../firebase';

const FemHost = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [snapchat, setSnapchat] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    db.collection('Female-Podcast-Host').add({
      name: name,
      email: email,
      snapchat: snapchat,
    })
    .then(() => {
      window.location.href = "/apply/thank-you";
    })
    .catch(() => {
      alert("If you see this message, please contact the site administator on Instagram immediately (@prince.george_).");
    }) 

    setName("");
    setEmail("");
    setSnapchat("");
  };

  return (
    <div>
      <div className="jumbotron3 text-center"></div>
      <br />
      <section className="Application text-center">
      <h3>Application for Female Podcast Host</h3>

        <div className="container" align="center">
          <p>
              Project Noir has five founding members but in order to expand and connect with our female audience even better on certain topics, we are looking for a female podcast host to join our team. The role includes joining our team on selected epsiodes and being the sole host (alongside female guests) on episodes in which a male point of view isn't needed.
          </p>
          <h6>This role will be unpaid.</h6>
          <form onSubmit={handleSubmit}>
            <div className="form-group col-lg-6"> 
              <label>First Name: </label>
              <input  type="text"
                  required
                  className="form-control"
                  placeholder="First Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  />
            </div>
            <div className="form-group col-lg-6"> 
              <label>Email Address: </label>
              <input  type="text"
                  required
                  className="form-control"
                  placeholder="Email Address"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  />
            </div>
            <div className="form-group col-lg-6"> 
              <label>Snapchat: </label>
              <input  type="text"
                  required
                  className="form-control"
                  placeholder="Snapchat"
                  value={snapchat}
                  onChange={(e) => setSnapchat(e.target.value)}
                  />
            </div>
            <div className="form-group">
              <input type="submit" value="Submit" className="btn btn-primary" />
            </div>
          </form>
        </div>
        <br />
      </section>
    </div>
  )
}

export default FemHost;