import React, { useState } from 'react';
import { db } from '../firebase';

const MAff = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    db.collection('MAff-Applicants').add({
      name: name,
      email: email,
    })
    .then(() => {
      window.location.href = "/apply/thank-you";
    })
    .catch(() => {
      alert("If you see this message, please contact the site administator on Instagram immediately (@prince.george_).");
    }) 

    setName("");
    setEmail("");
  };

  return (
    <div>
      <div className="jumbotron4 text-center"></div>
      <br />
      <section className="Application text-center">
      <h3>Application for Male Affirmations</h3>

        <div className="container" align="center">
          <p>Through all of the trials and tribulations that we go through on a day to day, it is easy to forget your worth. At Project Noir, we have created this positive affirmation program to regularly remind you of your self-importance, as well as to give you direct reminders of the importance of emotional intelligence, critical thinking and more. These messages will be sent straight into your email at least every other week.</p>
          <form onSubmit={handleSubmit}>
            <div className="form-group col-lg-6"> 
              <label>First Name: </label>
              <input  type="text"
                  required
                  className="form-control"
                  placeholder="First Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  />
            </div>
            <div className="form-group col-lg-6"> 
              <label>Email Address: </label>
              <input  type="text"
                  required
                  className="form-control"
                  placeholder="Email Address"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  />
            </div>
            <div className="form-group">
              <input type="submit" value="Submit" className="btn btn-primary" />
            </div>
          </form>
        </div>
        <br />
      </section>
    </div>
  )
}

export default MAff;