import firebase from 'firebase'

var firebaseApp = firebase.initializeApp({
    apiKey: "AIzaSyAvcLIotlQEltFLq7qNxqyEPbYgKKRZQvc",
    authDomain: "projectnoir-df4b7.firebaseapp.com",
    projectId: "projectnoir-df4b7",
    storageBucket: "projectnoir-df4b7.appspot.com",
    messagingSenderId: "19441538452",
    appId: "1:19441538452:web:d740ebcc5a048728e9eb81"
})

var db = firebaseApp.firestore();

export { db };