import React, { Component } from 'react';
import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default class Home extends Component {

    render(){
        return (
            <div className="about-page">
                <div className="jumbotron2 text-center"></div>
                <br />
                <div className= "container text-center">
                    <h1>Episode 7 - Black and Highly Aspirational</h1>
                <div className="row py-4 d-flex align-items-center">
                    <div className="col-md-12 text-center"> 
                    <a href="https://open.spotify.com/episode/3jVMQm44Z6pOrFc3UKc88g?si=XQepYxY1RMa2_t5WCbApgA" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={['fab', 'spotify']} className="mr-3" size="3x"/></a>         
                    <a href="https://podcasts.apple.com/gb/podcast/episode-7-black-and-highly-aspirational/id1517444320?i=1000493001306" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={['fab', 'apple']} className="mr-3" size="3x" /></a>
                    <a href="https://www.youtube.com/watch?v=HQux0o6ykqY" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={['fab', 'youtube']} className="mr-3" size="3x" /></a>
                    </div>
                </div>
                    <p>
                    In this episode we discuss the mindset that our generation have when it comes to deciding their future and how society and their environment influences the choices they make when deciding their future career path. We look into why selling drugs and being on road is an attractive options to today’s youth and also reflect on why it was never a path that any of us on the panel fell into. This episode is especially useful for anybody who currently lacks direction as isn’t interested in making money that is long lasting and stable rather than quick and illegal.
                    </p>
                    <br />
                    <iframe allow="autoplay *; encrypted-media *; fullscreen *" frameBorder={0} height={175} title="ep7" style={{width: '100%', maxWidth: '660px', overflow: 'hidden', background: 'transparent'}} sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-storage-access-by-user-activation allow-top-navigation-by-user-activation" src="https://embed.podcasts.apple.com/gb/podcast/episode-7-black-and-highly-aspirational/id1517444320?i=1000493001306" />
                    <br />
                    <div className="row py-4 d-flex align-items-center">
                        <div className="col-md-12">
                            <a class="btn btn-primary mr-3" href="/episodes/6" role="button">Previous Episode</a>
                            <a class="btn btn-primary" href="/episodes/8" role="button">Next Episode</a>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}