import React, { useState } from 'react';
import { db } from '../firebase';

const Researcher = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [snapchat, setSnapchat] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    db.collection('Research-Applicants').add({
      name: name,
      email: email,
      snapchat: snapchat,
    })
    .then(() => {
      window.location.href = "/apply/thank-you";
    })
    .catch(() => {
      alert("If you see this message, please contact the site administator on Instagram immediately (@prince.george_).");
    }) 

    setName("");
    setEmail("");
    setSnapchat("");
  };

  return (
    <div>
      <div className="jumbotron5 text-center"></div>
      <br />
      <section className="Application text-center">
      <h3>Application for Researcher/Social Media Coordinator</h3>

        <div className="container" align="center">
          <p>
              Project Noir is expanding our social media pages to include more knowledge about black history. As part of this expansion, we are looking for people to produce facts about black history, both obscure and well known. An additional component of this role is learning to produce images that could be put on our instagram page. If you love black history, this is the role for you as you will be able to work as much or as little as you want.
          </p>
          <h6>This role will be unpaid.</h6>
          <form onSubmit={handleSubmit}>
            <div className="form-group col-lg-6"> 
              <label>First Name: </label>
              <input  type="text"
                  required
                  className="form-control"
                  placeholder="First Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  />
            </div>
            <div className="form-group col-lg-6"> 
              <label>Email Address: </label>
              <input  type="text"
                  required
                  className="form-control"
                  placeholder="Email Address"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  />
            </div>
            <div className="form-group col-lg-6"> 
              <label>Snapchat: </label>
              <input  type="text"
                  required
                  className="form-control"
                  placeholder="Snapchat"
                  value={snapchat}
                  onChange={(e) => setSnapchat(e.target.value)}
                  />
            </div>
            <div className="form-group">
              <input type="submit" value="Submit" className="btn btn-primary" />
            </div>
          </form>
        </div>
        <br />
      </section>
    </div>
  )
}

export default Researcher;