import React, { Component } from 'react';
import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default class Home extends Component {

    render(){
        return (
            <div className="about-page">
                <div className="jumbotron2 text-center"></div>
                <br />
                <div className= "container text-center">
                    <h1>Episode 15 - Black and Stereotyped (Part 2)</h1>
                <div className="row py-4 d-flex align-items-center">
                    <div className="col-md-12 text-center"> 
                    <a href="https://open.spotify.com/episode/3xodigL1WHrctwZ8LIhYl9?si=wt42Z5RrQGySlYYaMyhRiA" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={['fab', 'spotify']} className="mr-3" size="3x"/></a>         
                    <a href="https://podcasts.apple.com/gb/podcast/episode-15-black-and-stereotyped-part-2/id1517444320?i=1000516488798" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={['fab', 'apple']} className="mr-3" size="3x" /></a>
                    <a href="https://www.youtube.com/watch?v=D00rSQGTfm8" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={['fab', 'youtube']} className="mr-3" size="3x" /></a>
                    </div>
                </div>
                    <p>
                    This is the second part of our episode highlighting and addressing racism in Britain. Our guest Darren discusses the racism he has faced in his different workplaces and  advises us on how to handle these situations so we don't have to endure the same hardships he did.
                    </p>
                    <h5>
                        We would like to thank our guest Darren for appearing on this episode!
                    </h5>
                    <br />
                    <iframe allow="autoplay *; encrypted-media *; fullscreen *" title="Ep15" frameBorder={0} height={175} style={{width: '100%', maxWidth: '660px', overflow: 'hidden', background: 'transparent'}} sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-storage-access-by-user-activation allow-top-navigation-by-user-activation" src="https://embed.podcasts.apple.com/gb/podcast/episode-15-black-and-stereotyped-part-2/id1517444320?i=1000516488798" />
                    <br />
                    <div className="row py-4 d-flex align-items-center">
                    <div className="col-md-12">
                            <a class="btn btn-primary mr-3" href="/episodes/14" role="button">Previous Episode</a>
                            <a class="btn btn-primary" href="/episodes/16" role="button">Next Episode</a>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}