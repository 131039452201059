import React, { Component } from 'react';
import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default class Home extends Component {

    render(){
        return (
            <div className="about-page">
                <div className="jumbotron2 text-center"></div>
                <br />
                <div className= "container text-center">
                    <h1>Episode 8 - Black and Proud</h1>
                <div className="row py-4 d-flex align-items-center">
                    <div className="col-md-12 text-center"> 
                    <a href="https://open.spotify.com/episode/2GnEB9coWDG9eh6p3MxXF7?si=WA5_5eo9QA6HcQ2yTTz2rQ" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={['fab', 'spotify']} className="mr-3" size="3x"/></a>         
                    <a href="https://podcasts.apple.com/gb/podcast/episode-8-black-and-proud/id1517444320?i=1000496286685" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={['fab', 'apple']} className="mr-3" size="3x" /></a>
                    <a href="https://www.youtube.com/watch?v=_YdAskah-n4" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={['fab', 'youtube']} className="mr-3" size="3x" /></a>
                </div>
                </div>
                    <p>
                    Today we discuss the benefits of living within our black skin and how, despite all of the trials and tribulations, we wouldn't change our skin colour for any money in the world.  Join us as we discuss black food, music, memes, comedians, etc. on this journey of the glory of blackness.
                    </p>
                    <br />
                    <iframe allow="autoplay *; encrypted-media *; fullscreen *" frameBorder={0} height={175} title="ep8" style={{width: '100%', maxWidth: '660px', overflow: 'hidden', background: 'transparent'}} sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-storage-access-by-user-activation allow-top-navigation-by-user-activation" src="https://embed.podcasts.apple.com/gb/podcast/episode-8-black-and-proud/id1517444320?i=1000496286685" />
                    <br />
                    <div className="row py-4 d-flex align-items-center">
                        <div className="col-md-12">
                            <a class="btn btn-primary mr-3" href="/episodes/7" role="button">Previous Episode</a>
                            <a class="btn btn-primary" href="/episodes/9" role="button">Next Episode</a>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}