import React, { Component } from 'react';
import "bootstrap/dist/css/bootstrap.min.css";

export default class Contact extends Component {

    render(){
        return (
            <div className="about-page">
                <div className="jumbotron text-center"></div>
                <div className= "container text-center">
                    <h1>Contact us</h1>
                    <p>
                        There are many ways to reach us and chat to us directly.
                    </p>
                    <p>
                        Those of you with social media accounts may direct message us directly on either Instagram (<a href="https://www.instagram.com/projectnoir.uk">@projectnoir.uk</a>) or Twitter (<a href="https://www.twitter.com/projectnoir_uk">@projectnoir_uk</a>). The link to both of those pages are in the footer at the bottom of this page.
                    </p>
                    <p>
                        Please feel free to email us with any queries by clicking <a href="mailto:projectnoir@protonmail.com">here!</a>
                    </p>
                    <br />
                    <h4>Any donations you wish to make to Project Noir can be made <a href="https://www.paypal.me/projectnoiruk">here.</a></h4>
                    <h5>Donations will be used to cover equipment and travel costs as well as website related costs, Thank you!</h5>
                </div>
                <br />
            </div>
        )
    }
}