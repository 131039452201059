import React, { Component } from 'react';
import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default class Home extends Component {

    render(){
        return (
            <div className="about-page">
                <div className="jumbotron2 text-center"></div>
                <br />
                <div className= "container text-center">
                    <h1>Episode 12 - Black and in a Pandemic</h1>
                <div className="row py-4 d-flex align-items-center">
                    <div className="col-md-12 text-center"> 
                    <a href="https://open.spotify.com/episode/1uGSqSqRTWtplmiHdyNWyo?si=c1Vcq1bES4-HmmkLoLtbgw" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={['fab', 'spotify']} className="mr-3" size="3x"/></a>         
                    <a href="https://podcasts.apple.com/gb/podcast/episode-12-black-and-in-a-pandemic/id1517444320?i=1000507219973" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={['fab', 'apple']} className="mr-3" size="3x" /></a>
                    <a href="https://www.youtube.com/watch?v=0wexvAsxIzE" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={['fab', 'youtube']} className="mr-3" size="3x" /></a>
                    </div>
                </div>
                    <p>
                    Many things have come out of this pandemic, including this podcast, and in today's episode we take the opportunity to analyse the way that life has changed for all of us. We discuss changes in education, working and business. Another discussion point for this episode was how the pandemic has been handled by the UK and US governments as we put ourselves in the shoes of the people chosen to lead us out of this unexpected crisis.
                    </p>
                    <h5>
                        We would like to thank our guest Tiffany for appearing on this episode!
                    </h5>
                    <br />
                    <iframe allow="autoplay *; encrypted-media *; fullscreen *" frameBorder={0} title="ep13" height={175} style={{width: '100%', maxWidth: '660px', overflow: 'hidden', background: 'transparent'}} sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-storage-access-by-user-activation allow-top-navigation-by-user-activation" src="https://embed.podcasts.apple.com/gb/podcast/episode-12-black-and-in-a-pandemic/id1517444320?i=1000507219973" />
                    <br />
                    <div className="row py-4 d-flex align-items-center">
                        <div className="col-md-12">
                            <a class="btn btn-primary mr-3" href="/episodes/11" role="button">Previous Episode</a>
                            <a class="btn btn-primary" href="/episodes/13" role="button">Next Episode</a>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}