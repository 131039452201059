import React, { Component } from 'react';
import "bootstrap/dist/css/bootstrap.min.css";

export default class Thanks extends Component {

    render(){
        return (
            <div className="about-page">
                <div className="jumbotron11 text-center"></div>
                <div className= "container text-center">
                    <br />
                    <h1>Thank you for applying!</h1>
                    <br />
                    <p>
                    Feel free to apply to more of the programs/roles that we are offering.
                    </p>
                    <p>We will get back to you as soon as possible.</p>
                    <br />
                </div>
            </div>
        )
    }
}