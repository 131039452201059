import React, { Component } from 'react';
import "bootstrap/dist/css/bootstrap.min.css";

export default class Apply extends Component {

    render(){
        return (
            <div className="episodes-page">
                <div className="jumbotron3 text-center"></div>
                <br />
                <div className="container text-center">
                <h1>Apply</h1>
                <p>Welcome to the apply page! Project Noir are looking to expand and in doing this, we are opening opportunities to join our team as well as opportunities to access services that Project Noir now provide. Enjoy!</p>
                </div>
                <div className="pn-section">
                <ul className="pn-grid">
                <li>
                    <div className="pn-box apply-img1">
                    <a href="/apply/come-on-episode">
                        <div className="pn-info">
                        <h3>Come on an Episode</h3>
                        </div></a>
                    </div>
                </li>
                <li>
                    <div className="pn-box apply-img2">
                    <a href="/apply/what-is-a-man">
                        <div className="pn-info">
                            <br />
                        <h4>"What is a Man?" Book Club</h4>
                        </div></a>
                    </div>
                </li>
                <li>
                    <div className="pn-box apply-img3">
                    <a href="/apply/black-history-book-club">
                        <div className="pn-info">
                        <h3>Black History Book Club</h3>
                        </div></a>
                    </div>
                </li>
                <li>
                    <div className="pn-box apply-img4">
                    <a href="/apply/affirmations-for-men">
                        <div className="pn-info">
                        <h3>Male Affirmations</h3>
                        </div></a>
                    </div>
                </li>
                <li>
                    <div className="pn-box apply-img5">
                    <a href="/apply/affirmations-for-women">
                        <div className="pn-info">
                        <h3>Female Affirmations</h3>
                        </div></a>
                    </div>
                </li>
                </ul>
                </div>
                <br />
                <div className="container text-center">
                <h3>The rest of the roles on this page are to join Project Noir in a voluntary position.</h3>
                </div>
                <div className="pn-section">
                <ul className="pn-grid">
                <li>
                    <div className="pn-box apply-img6">
                    <a href="/apply/affirmations-writer">
                        <div className="pn-info">
                        <h3>Affirmations Writer</h3>
                        </div></a>
                    </div>
                </li>
                <li>
                    <div className="pn-box apply-img7">
                    <a href="/apply/female-podcast-host">
                        <div className="pn-info">
                            <br />
                        <h3>Female Podcast Host</h3>
                        </div></a>
                    </div>
                </li>
                <li>
                    <div className="pn-box apply-img8">
                    <a href="/apply/social-media-coordinator">
                        <div className="pn-info">
                        <h4>Researcher/Social Media Coordinator</h4>
                        </div></a>
                    </div>
                </li>
                </ul>
            </div>
        </div>
        )
    }
}