import React, { Component } from 'react';
import "bootstrap/dist/css/bootstrap.min.css";

export default class Episodes extends Component {

    render(){
        return (
            <div className="episodes-page">
                <div className="jumbotron text-center"></div>
                <div className="container text-center">
                <h1>Episode List</h1>
                <p>Click on any episode to find more information about the episode as well as links to where you can listen!</p>
                </div>
                <div className="pn-section">
                <ul className="pn-grid">
                <li>
                    <div className="pn-box img1">
                    <a href="/episodes/1">
                        <div className="pn-info">
                        <h3>Black and Protesing</h3>
                        <p>Episode 1</p>
                        </div></a>
                    </div>
                </li>
                <li>
                    <div className="pn-box img2">
                    <a href="/episodes/2">
                        <div className="pn-info">
                        <h3>Black and Oppressed</h3>
                        <p>Episode 2</p>
                        </div></a>
                    </div>
                </li>
                <li>
                    <div className="pn-box img3">
                    <a href="/episodes/3">
                        <div className="pn-info">
                        <h3>Black and Privately Educated</h3>
                        <p>Episode 3</p>
                        </div></a>
                    </div>
                </li>
                <li>
                    <div className="pn-box img4">
                    <a href="/episodes/4">
                        <div className="pn-info">
                        <h3>Black and Interracial Dating</h3>
                        <p>Episode 4</p>
                        </div></a>
                    </div>
                </li>
                <li>
                    <div className="pn-box img5">
                    <a href="/episodes/5">
                        <div className="pn-info">
                        <h3>Black and Addressing Mental Health</h3>
                        <p>Episode 5</p>
                        </div></a>
                    </div>
                </li>
                <li>
                    <div className="pn-box img6">
                    <a href="/episodes/6">
                        <div className="pn-info">
                        <h3>Black and Colourist</h3>
                        <p>Episode 6</p>
                        </div></a>
                    </div>
                </li>
                <li>
                    <div className="pn-box img7">
                    <a href="/episodes/7">
                        <div className="pn-info">
                        <h3>Black and Highly Aspirational</h3>
                        <p>Episode 7</p>
                        </div></a>
                    </div>
                </li>
                <li>
                    <div className="pn-box img8">
                    <a href="/episodes/8">
                        <div className="pn-info">
                        <h3>Black and Proud</h3>
                        <p>Episode 8</p>
                        </div></a>
                    </div>
                </li>
                <li>
                    <div className="pn-box img9">
                    <a href="/episodes/9">
                        <div className="pn-info">
                        <h3>Black and Educated</h3>
                        <p>Episode 9</p>
                        </div></a>
                    </div>
                </li>
                <li>
                    <div className="pn-box img10">
                    <a href="/episodes/10">
                        <div className="pn-info">
                        <h3>Black and Parenting</h3>
                        <p>Episode 10</p>
                        </div></a>
                    </div>
                </li>
                <li>
                    <div className="pn-box img11">
                    <a href="/episodes/11">
                        <div className="pn-info">
                        <h3>Black and Rebuilding</h3>
                        <p>Episode 11</p>
                        </div></a>
                    </div>
                </li>
                <li>
                    <div className="pn-box img12">
                    <a href="/episodes/12">
                        <div className="pn-info">
                        <h3>Black and in a Pandemic</h3>
                        <p>Episode 12</p>
                        </div></a>
                    </div>
                </li>
                <li>
                    <div className="pn-box img13">
                    <a href="/episodes/13">
                        <div className="pn-info">
                        <h3>Black and Being a Man</h3>
                        <p>Episode 13</p>
                        </div></a>
                    </div>
                </li>
                <li>
                    <div className="pn-box img14">
                    <a href="/episodes/14">
                        <div className="pn-info">
                        <h3>Black and Stereotyped</h3>
                        <p>Episode 14</p>
                        </div></a>
                    </div>
                </li>
                <li>
                    <div className="pn-box img15">
                    <a href="/episodes/15">
                        <div className="pn-info">
                        <h3>Black and Stereotyped 2</h3>
                        <p>Episode 15</p>
                        </div></a>
                    </div>
                </li>
                <li>
                    <div className="pn-box img16">
                    <a href="/episodes/16">
                        <div className="pn-info">
                        <h3>Black and Insecure</h3>
                        <p>Episode 16</p>
                        </div></a>
                    </div>
                </li>
                <li>
                    <div className="pn-box img17">
                    <a href="/episodes/17">
                        <div className="pn-info">
                        <h3>Black and Dating</h3>
                        <p>Episode 17</p>
                        </div></a>
                    </div>
                </li>
                <li>
                    <div className="pn-box img18">
                    <a href="/episodes/18">
                        <div className="pn-info">
                        <h3>Black and Free</h3>
                        <p>Episode 18</p>
                        </div></a>
                    </div>
                </li>
                <li>
                    <div className="pn-box img19">
                    <a href="/episodes/19">
                        <div className="pn-info">
                        <h3>Black and Disciplined</h3>
                        <p>Episode 19</p>
                        </div></a>
                    </div>
                </li>
                <li>
                    <div className="pn-box img20">
                    <a href="/episodes/20">
                        <div className="pn-info">
                        <h3>Black and Dating 2</h3>
                        <p>Episode 20</p>
                        </div></a>
                    </div>
                </li>
                </ul>
                </div>
            </div>
        )
    }
}