import React, { Component } from 'react';
import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default class Navbar extends Component {

    render(){
      return (
        <footer className="page-footer pn-dark-grey">
            <div className="pn-darkest-grey">
                <div className="container">
                <div className="row py-4 d-flex align-items-center">
                    <div className="col-md-12 text-center"> 
                    <a href="https://open.spotify.com/show/5jKHIikUWyTofbeD8LLkFo?si=oUfJ1oleTwC53c59nddypw"  target="_blank" rel="noreferrer"><FontAwesomeIcon icon={['fab', 'spotify']} className="pn-white mr-3" size="3x"/></a>         
                    <a href="https://podcasts.apple.com/gb/podcast/project-noir/id1517444320"  target="_blank" rel="noreferrer"><FontAwesomeIcon icon={['fab', 'apple']} className="pn-white mr-3" size="3x" /></a>
                    <a href="https://www.youtube.com/channel/UCWnPGumtOcs8_7CoApTIv_Q?sub_confirmation=1"  target="_blank" rel="noreferrer"><FontAwesomeIcon icon={['fab', 'youtube']} className="pn-white mr-3" size="3x" /></a>
                    <a href="https://www.instagram.com/projectnoir.uk/"  target="_blank" rel="noreferrer"><FontAwesomeIcon icon={['fab', 'instagram']} className="pn-white mr-3" size="3x"/></a>
                    <a href="https://twitter.com/projectnoir_uk"  target="_blank" rel="noreferrer"><FontAwesomeIcon icon={['fab', 'twitter']} className="pn-white" size="3x"/></a>
                    </div>
                </div>
                </div>
            </div>
            <div className="container text-center text-md-left mt-5">
                <div className="row">
                <div className="col-md-3 mx-auto mb-4">
                    <h6 className="pn-white text-uppercase font-weight-bold">Thank You</h6>
                    <hr className="pn-bg-gold mb-4 mt-0 d-inline-block mx-auto" style={{width: '100px', height: '2px'}} />
                    <p className="pn-white mt-2">A lot of work has been put into this website and Project Noir as a whole so we would like to take this time to thank you for your continued support, you are truly valued. Feel free to messsage our pages at any time for someone to talk to!</p>
                </div>
                <div className="col-md-2 mx-auto mb-3">
                    <h6 className="pn-white text-uppercase font-weight-bold">Episodes</h6>
                    <hr className="pn-bg-gold mb-4 mt-0 d-inline-block mx-auto" style={{width: '85px', height: '2px'}} />
                    <ul className="list-unstyled">
                    <li className="my-2"><a className="pn-white" href="/episodes">Episode List</a></li>        
                    <li className="my-2"><a className="pn-white" href="/episodes/1">First Episode</a></li>
                    <li className="my-2"><a className="pn-white" href="/episodes/13">Latest Episode</a></li>    
                    </ul>
                </div>
                <div className="col-md-3 mx-auto mb-4">
                    <h6 className="pn-white text-uppercase font-weight-bold">Links to Services</h6>
                    <hr className="pn-bg-gold mb-4 mt-0 d-inline-block mx-auto" style={{width: '160px', height: '2px'}} />
                    <ul className="list-unstyled">
                    <li className="my-2"><a className="pn-white" href="/apply/come-on-episode">Come on Episode</a></li>        
                    <li className="my-2"><a className="pn-white" href="/apply/affirmations-for-men">Male Affirmations</a></li>
                    <li className="my-2"><a className="pn-white" href="/apply/affirmations-for-women">Female Affirmations</a></li>
                    <li className="my-2"> <a className="pn-white" href="/apply/what-is-a-man">"What is a Man?" Book Club</a></li>
                    <li className="my-2"> <a className="pn-white" href="/apply/black-history-book-club">Black History Book Club</a></li>          
                    </ul>
                </div>
                </div>
            </div>
            <div className="footer-copyright text-center py-3">
                <p>Designed by the Project Noir team</p>
            </div>
        </footer>
      );
    }
}