import React, { Component } from 'react';
import "bootstrap/dist/css/bootstrap.min.css";

export default class About extends Component {

    render(){
        return (
            <div className="about-page">
                <div className="jumbotron9 text-center"></div>
                <br />
                <div className= "container text-center">
                    <h1>About us</h1>
                    <blockquote class="pull-quote">
                        <q>In June 2020, during the peak of that summer's Black Lives Matter Movement, I found myself having deeply inellectual conversations with many different people. In these conversations, discussions were brought up that I believed the public should be able to hear as they strayed from the commonly discussed narratives at the time. It was amidst this important time in the BLM movement's struggle for recognition that I decided to get a selection of friends to create a podcast... This podcast, Project Noir.</q>
                    <p>- Project Noir Prince</p>
                    </blockquote>
                    <p>
                    We are all living in a time where so many voices are speaking at once, Project Noir hopes to provide clarity and a new perspective to many of the modern issues which persist in society.
                    </p>
                    <br />
                </div>
            </div>
        )
    }
}